<template>
  <div>
    <!-- <team-list-add-new
            :is-add-new-team-sidebar-active.sync="isAddNewTeamSidebarActive"
            @refetch-data="refetchData"
        >
        </team-list-add-new> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">CATEGORY</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search Team"
            />
          </div>
          <b-button
            variant="primary"
            class="px-3 mobile-w100"
            :to="{ name: 'teams-create' }"
            v-if="ability.can('create', 'team')"
          >
            <span class="text-nowrap">
              <feather-icon size="14" icon="PlusIcon" /> CATEGORY
            </span>
          </b-button>
        </div>

        <div class="d-flex flex-wrap mt-2 mt-sm-1 flex-sm-nowrap">
          <b-button
            class="mr-2 mt-1 mt-sm-0"
            variant="primary"
            @click="statusFilter = null"
          >
            <feather-icon icon="AlignJustifyIcon" class="mr-50" />
            All
          </b-button>
          <b-button
            class="mr-2 mt-1 mt-sm-0"
            variant="primary"
            @click="statusFilter = '1'"
          >
            <feather-icon icon="BookOpenIcon" class="mr-50" />
            Active
          </b-button>
          <b-button
            class="mr-2 mt-1 mt-sm-0"
            variant="primary"
            @click="statusFilter = '0'"
          >
            <feather-icon icon="LoaderIcon" class="mr-50" />
            Inactive
          </b-button>
        </div>
      </div>

      <b-table
        ref="refTeamListTable"
        class="position-relative"
        :items="fetchTeams"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(en_name)="data">
          <b-badge
            v-bind:style="{
              'background-color': data.item.background_color,
              color: data.item.font_color,
            }"
            >{{ data.item.en_name }}
          </b-badge>
        </template>

        <template #cell(is_active)="data">
          <div v-if="data.item.is_active == 1" size="18" class="mr-50 text-success">
            Active
          </div>
          <div v-if="data.item.is_active == 0" size="18" class="mr-50 text-danger">
            Inactive
          </div>
        </template>

        <!-- Column: Show Detail -->
        <template #cell(show_details)="row">
          <b-button
            v-if="row.item.users.length > 0"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card v-if="row.item.users">
            <b-row>
              <b-badge
                v-for="(item, index) in row.item.users"
                :key="index"
                class="text-capitalize"
                style="display: inline"
              >
                {{ item.name }}
              </b-badge>
            </b-row>
          </b-card>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item
              :to="{ name: 'teams-edit', params: { id: data.item.id } }"
              v-if="ability.can('update', 'team')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="ability.can('delete', 'team')"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTeams"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import teamStoreModule from "../teamStoreModule";
import useTeamsList from "./useTeamsList";
import TeamListAddNew from "./TeamListAddNew.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TeamListAddNew,
  },
  methods: {
    editMenu(id) {},
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the team.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-team/deleteTeam", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // addTeam(){
    //     this.isAddNewTeamSidebarActive = true;
    // }
  },
  setup() {
    const APP_TEAM_STORE_MODULE_NAME = "app-team";
    const isAddNewTeamSidebarActive = ref(false);

    if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
      store.registerModule(APP_TEAM_STORE_MODULE_NAME, teamStoreModule);

    const {
      fetchTeams,
      tableColumns,
      perPage,
      currentPage,
      totalTeams,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refTeamListTable,
      ability,
      statusFilter,
    } = useTeamsList();

    return {
      isAddNewTeamSidebarActive,
      fetchTeams,
      tableColumns,
      perPage,
      currentPage,
      totalTeams,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refTeamListTable,
      ability,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
